import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import React from "react";
import { DialogActions, DialogContent, DialogTitle } from "./dialogCommons";

export interface ConfirmationDialogRawProps {
    id: string;
    keepMounted: boolean;
    value?: string;
    open: boolean;
    onClose: (isOk?: boolean, data?: any) => void;
    title: string;
    content: React.ReactNode;
}

export const ConfirmationDialogRaw = (props: ConfirmationDialogRawProps) => {
    const { onClose, open, content, ...other } = props; // Tách `content` ra

    const handleCancel = () => {
        onClose(false);
    };

    const handleOk = () => {
        onClose(true, other.value);
    };

    return (
        <Dialog
            disableBackdropClick
            disableEscapeKeyDown
            fullWidth
            maxWidth="sm"
            aria-labelledby="confirmation-dialog-title"
            open={open}
            {...other} // Truyền các thuộc tính khác, trừ `content`
        >
            <DialogTitle id={other.id} onClose={handleCancel}>
                {other.title}
            </DialogTitle>
            <DialogContent dividers>
                {content} {/* Hiển thị nội dung */}
            </DialogContent>
            <DialogActions>
                <Button variant="contained" color="primary" disableElevation onClick={handleOk}>
                    Đồng ý
                </Button>
                <Button variant="contained" color="default" disableElevation onClick={handleCancel}>
                    Hủy
                </Button>
            </DialogActions>
        </Dialog>
    );
};
