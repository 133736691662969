import { lazy } from "react";
import { RoutePropsI } from "routes";

const WrapperMapTraCuu = lazy(() => import("pages/hoatDongNganhIn/traCuuBanDo/wrapperTraCuuBanDo"));
const DashboardThuySanComponent = lazy(() => import("pages/dashboard/DashboardThuySanComponent"));
export const ROUTE_PAGE_PUBLIC: RoutePropsI[] = [
    {
        ten: "Biểu đồ giấy chứng nhận đủ đIều kiện vnmệ sinh thú y",
        duongDan: "/tra-cuu-ban-do",
        giaoDien: <WrapperMapTraCuu />,
    },
    {
        ten: "dashboard",
        duongDan: "/dashboard",
        giaoDien: <DashboardThuySanComponent />,
    },
];
