import { resetAuthenticatedSession } from "actions/session";
import axios from "axios";
import { AUTHORIZE_REQUEST } from "config";
import StringUtil from "utils/stringUtils";

const HOST = {
    DEV: "http://localhost:5058/tttt-apis",
    FIS: "http://10.86.222.77:8086/tttt-apis",
    LIVE: "https://thongtintruyenthong.congchuc.cantho.gov.vn/tttt-apis",
    KIEMTHU: "http://14.238.93.157:8084/tttt-apis"
};

const AXIOS_INSTANCE = axios.create({
    baseURL: HOST.LIVE,
    timeout: 600000,
    withCredentials: true,
    headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Headers': 'Content-Type, Access-Control-Allow-Headers, Authorization, X-Requested-With, csrf-token',
        'Access-Control-Expose-Headers': 'csrf-token',
        'withCredentials': true
    }
});

AXIOS_INSTANCE.interceptors.request.use((request) => {
    const csrfToken = localStorage.getItem("csrf-token");

    if (!StringUtil.isNullOrEmty(csrfToken)) {
        request.headers['csrf-token'] = csrfToken;
    }

    return request;
}, error => {
    // Handle request error if needed
    return Promise.reject(error);
});

AXIOS_INSTANCE.interceptors.response.use((response) => {
    if (!StringUtil.isNullOrEmty(response.headers['csrf-token'])) {
        localStorage.setItem('csrf-token', response.headers['csrf-token']);
    }
    return response;
}, error => {
    if (error.response && (error.response.status === 401 || error.response.status === 403 || error.response.status === 406)) {
        if (localStorage.getItem("PRIVATE_LOGIN") && localStorage.getItem("PRIVATE_LOGIN") === "1") {
            window.location.href = '/login';
        } else {
            resetAuthenticatedSession();
            window.location.href = AUTHORIZE_REQUEST;
        }
    } else {
        // Handle other errors
        return Promise.reject(error);
    }
});

export default AXIOS_INSTANCE;
